import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DxTextBoxModule } from 'devextreme-angular';

import { VendorPopupModule } from '../vendor-popup/vendor-popup.module';

import { VendorHelperRoutingModule } from './vendor-helper-routing.module';
import { VendorHelperComponent } from './vendor-helper.component';
import { VendorHelperService } from './vendor-helper.service';

@NgModule({
    declarations: [
        VendorHelperComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,

        DxTextBoxModule,

        VendorPopupModule,

        VendorHelperRoutingModule
    ],
    exports: [
        VendorHelperComponent
    ],
    providers: [
        VendorHelperService
    ]
})
export class VendorHelperModule { }
