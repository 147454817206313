import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';

import { alert } from 'devextreme/ui/dialog';

import { VendorHelperService } from './vendor-helper.service';
import { VendorPopupComponent } from '../vendor-popup/vendor-popup.component';

@Component({
    selector: 'vendor-helper',
    templateUrl: './vendor-helper.component.html',
    styleUrls: ['./vendor-helper.component.scss']
})
export class VendorHelperComponent implements OnInit {
    @ViewChild('vendorPopup') vendorPopup: VendorPopupComponent;

    /**
     * 거래처코드 너비
     */
    @Input() codeWidth = '120px';

    /**
     * 
     */
    @Input() nameVisible = true;

    /**
     * 
     */
    @Input() readOnly = false;

    /**
     * 
     */
    @Input() vendorCode = "";

    /**
     * 
     */
    @Input() vendorName = "";

    /**
     * 
     */
    @Output() vendorChanged = new EventEmitter<any>();

    constructor(
        private vendorHelperService: VendorHelperService
    ) {

    }

    ngOnInit(): void {

    }

    /**
     * 거래처코드 텍스트박스에서 엔터키 입력시
     * @param e 
     */
    onVenderCodeEnterKey = (e: any): void => {
        this.vendorHelperService.retrieveVendor({ VENDOR: this.vendorCode })
            .then(vendors => {
                if (vendors.length === 1) {
                    this.vendorCode = vendors[0].VCODE;
                    this.vendorName = vendors[0].VNAME1;

                    this.vendorChanged.emit(vendors[0]);
                } else {
                    this.vendorPopup.show();

                    this.vendorPopup.searchFormData.VENDOR = this.vendorCode
                    this.vendorPopup.retrieveVendors();
                }
            })
            .catch(error => alert(`거래처 조회시 아래의 오류 발생</br>${ error.details || error }`, '조회 오류'));
    }

    /**
     * 헬프버튼 클릭시
     * @param e 
     */
    onHelpButtonClick = (): void => {
        this.vendorPopup.show();
    }

    /**
     * 
     * @param e 
     */
    onVendorSelected(e: any): void {
        this.vendorCode = e.selectedRowsData[0].VCODE;
        this.vendorName = e.selectedRowsData[0].VNAME1;

        this.vendorChanged.emit(e.selectedRowsData[0]);
    }
}
