import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

export enum AuthSetupType {
  LOW_GROUP = 1,
  MENU_ITEM = 2,
}
@Injectable({
  providedIn: 'root',
})
export class M3W5150Service {
  // 리졸브가 있으면 리졸브에서 구독을 만들어서 서비스에 객체를 가지고 있는 형태로 처리하고
  // 일반적인 경우라면, 핫옵저버만 만들고 프라미스로 서비스 호출하여 구독은 컴포넌트에서 처리토록 한다.

  onAuthGroupSetupListChanged: BehaviorSubject<any>;

  constructor(private httpClient: HttpClient) {
    // 리스트 대응
    this.onAuthGroupSetupListChanged = new BehaviorSubject([]);
  }

  /**
   * 확보된 데이터를 변형하는 케이스.
   * @param value
   */
  /*getAuthGroupSetupList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .get<any>(`/v1/sys/m2-3008/m3-w5150/auth-group-setup-all`)
        .pipe(
          map(data => {
            // //console.log('result ====>', data);
            // return data.rows.slice(0, 100);
            return data.rows;
          }),
          map(data => {
            return data.map(e => {
              return {...e, SET_YN: e.SET_YN === 1 ? true : false};
            });
          }),
          filter(data => {
            return data;
          })
        )
        .subscribe(resultData => {
          this.onAuthGroupSetupListChanged.next(resultData);
          resolve();
        }, reject);
    });
  }*/

  getAuthGroupSetupList(params: any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/auth-group-setup-all`,
        params
      )
      .pipe(
        map(data => {
          //console.log('result ====>', data);
          // return data.rows.slice(0, 100);
          return data.rows;
        }),
        map(data => {
          return data.map(e => {
            return { ...e, CON_RETRIEVE: e.CON_RETRIEVE === 'Y' ? true : false
                         , CON_INSERT: e.CON_INSERT === 'Y' ? true : false
                         , CON_DELETE: e.CON_DELETE === 'Y' ? true : false
                         , CON_SAVE: e.CON_SAVE === 'Y' ? true : false
                         , CON_PRINTVIEW: e.CON_PRINTVIEW === 'Y' ? true : false
                         , CON_PRINTREPORT: e.CON_PRINTREPORT === 'Y' ? true : false
                         , FUNC_RETRIEVE: e.FUNC_RETRIEVE === 'Y' ? true : false
                         , FUNC_INSERT: e.FUNC_INSERT === 'Y' ? true : false
                         , FUNC_DELETE: e.FUNC_DELETE === 'Y' ? true : false
                         , FUNC_SAVE: e.FUNC_SAVE === 'Y' ? true : false
                         , FUNC_PRINTVIEW: e.FUNC_PRINTVIEW === 'Y' ? true : false
                         , FUNC_PRINTREPORT: e.FUNC_PRINTREPORT === 'Y' ? true : false
                         , FUNC_EXCEL: e.FUNC_EXCEL === 'Y' ? true : false
                  };
          });
        }),
        filter(data => {
          return data;
        })
      );
  }

  getAuthMenuFuncList(params: any): Observable<any> {
    //console.log('menu params===>', params);
    return this.httpClient
      .post<any>(
        `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/auth-menu-func`,
        params
      )
      .pipe(
        map(data => {
          //console.log('result ====>', data);
          // return data.rows.slice(0, 100);
          return data.rows;
        }),
        map(data => {
          return data.map(e => {
            return { ...e, USE_YN: e.USE_YN === 'Y' ? true : false };
          });
        }),
        filter(data => {
          return data;
        })
      );
  }

  insertItem(v: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/insert-item`,
      v
    );
  }

  deleteItem(v: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/delete-item`,
      v
    );

    /*if (v.type === AuthSetupType.LOW_GROUP) {
      return this.httpClient.post<any>(
        `/v1/sys/m2-3008/m3-w5150/delete-low-group`,
        v.data
      );
    } else if (v.type === AuthSetupType.MENU_ITEM) {
      return this.httpClient.post<any>(`/v1/sys/m2-3008/m3-w5150/delete-item`, v.data);
    }*/
  }

  insertFunc(v: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/insert-func`,
      v
    );
  }

  deleteFunc(v: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/delete-func`,
      v
    );
  }
  /*
  AuthGroupSetupList(): Promise<any> {

    return new Promise((resolve, reject) => {

      this.httpClient.get<any>(`/v1/sys/m2-3008/m3-w5150/authGroupList`)
        .pipe(
          map(data => {
            //console.log('result ====>', data);
            return data.rows.slice(0, 10);
          }),
          filter( data => {
            return data;
          })
        )
        .subscribe( (data) => {
          //console.log(data);
          resolve();
        }, reject);
    });
  }*/
  getAuthGroupList(params: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/auth-group-list`, params)
      .pipe(
        map(data => {
          // //console.log('result ====>', data);
          // return data.rows.slice(0, 100);
          return data.rows;
        })
      );
  }

  // 중분류 조회
  getMenuMidList(params: any): Observable<any> {
    //console.log('중분류조회==>', params);
    return this.httpClient
      .post<any>(`${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/menu-mid-list`, params)
      .pipe(
        map(data => {
          // //console.log('result ====>', data);
          // return data.rows.slice(0, 100);
          return data.rows;
        })
      );
  }

  getAuthSetupList(params: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/auth-setup-list`, params)
      .pipe(
        map(data => {
          // //console.log('result ====>', data);
          // return data.rows.slice(0, 100);
          return data.rows;
        })
      );
  }

  insertLowGroup(params: any): Observable<any> {
    //console.log(params);
    return this.httpClient.post<any>(
      `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/insert-lowgroupcode`,
      params
    );
  }

  deleteLowGroup(params: any): Observable<any> {
    //console.log(params);
    return this.httpClient.post<any>(
      `${environment.baseUrl}/v1/sys/m2-3008/m3-w5150/delete-lowgroupcode`,
      params
    );
  }
}
