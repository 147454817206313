import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

@Injectable()
export class VendorHelperService {
    private appUrl = '/v1/cmm';

    constructor(
        private httpClient: HttpClient
    ) {

    }

    retrieveVendor(params: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(`${ environment.baseUrl + this.appUrl }/retrieve-vendor`, params)
                .subscribe(
                    res => resolve(res.rows),
                    err => reject(err)
                );
        });
    }
}
